import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'helpers/linkResolver';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

function News(props) {
  const { data } = props;
  const doc = data.prismicNewsArticle.data;
  if (!doc) return null;
  return (
    <Layout>
      <Container maxWidth="lg">
        <Box my={10}>
          <Typography variant="h2" component="h2" align="center">
            {doc.title.text}
          </Typography>
        </Box>
        <Box
          component={Grid}
          container
          justifyContent="space-between"
          flexDirection="row-reverse"
        >
          <Grid item xs={12} md={6}>
            <Box style={{ color: '#fff' }} mb={[5, 5, 0]}>
              <RichText
                render={doc.content.raw}
                htmlSerializer={htmlSerializer}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            {doc.images && doc.images[0] && doc.images[0].image && (
              <React.Fragment>
                {doc.images.map((item, key) => {
                  return (
                    <Box key={key}>
                      <img
                        className="w-100"
                        src={item.image.url}
                        alt={item.image.alt ? item.image.alt : ''}
                      />
                    </Box>
                  );
                })}
              </React.Fragment>
            )}
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
}

export default withPrismicPreview(News, [
  {
    repositoryName: 'rectory-housing',
    linkResolver,
  },
]);

News.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query NewsPageQuery($id: String!) {
    prismicNewsArticle(id: { eq: $id }) {
      _previewable
      uid
      data {
        article_type
        content {
          html
          raw
          text
        }
        images {
          image {
            alt
            url
          }
        }
        listing_image {
          alt
          url
        }
        title {
          html
          raw
          text
        }
      }
    }
  }
`;
